import { Controller } from '@hotwired/stimulus'
import { EventBus } from '../utils/event_bus'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class extends Controller {
  static targets = [ "modal", "basket", "body" ]
  static values = {
    hide: { type: Boolean, default: false }
  }

  initialize() {
    this.toggle = this.toggle.bind(this);
  }  

  connect() {
    EventBus.on('basket--toggle', this.toggle);
    if (this.hideValue) {
      this.hide();
    }
    this.applyScrollHint = this.applyScrollHint.bind(this)
    this.checkForOverflow = this.checkForOverflow.bind(this)

    this.createObserver();
    
    window.addEventListener('resize', this.applyScrollHint)
    this.applyScrollHint()

    setInterval(this.applyScrollHint, 500);
  }

  disconnect() {
    EventBus.off('basket--togle', this.toggle);
    this.observer.disconnect();
    window.removeEventListener('resize', this.applyScrollHint)
    clearAllBodyScrollLocks();
  }

  toggle() {
    this.modalTarget.classList.toggle("is-visible")
    this.basketTarget.classList.toggle("is-visible")
    if (this.modalTarget.classList.contains("is-visible")) {
      disableBodyScroll(this.bodyTarget, { reserveScrollBarGap: true });
      // disableBodyScroll(this.bodyTarget);
    } else {
      enableBodyScroll(this.bodyTarget);
    }
  }

  hide() {
    this.modalTarget.classList.remove("is-visible")
    this.basketTarget.classList.remove("is-visible")
    enableBodyScroll(this.basketTarget);
  }

  clickOutside(e) {
    if (e.target.className === 'modal-body') {
      this.hide()
    }
  }

  // Observer to watch for items being added or removed in the basket
  createObserver() {
    let observer;
  
    let options = {
      threshold: [0, 1.0]
    };
    
    this.observer = new MutationObserver(this.applyScrollHint);
    this.observer.observe(this.element, { childList: true, subtree: true });
  }


  applyScrollHint () {
    if (this.checkForOverflow(this.bodyTarget)) {
      this.basketTarget.classList.add('is-overflowing')
    } else {
      this.basketTarget.classList.remove('is-overflowing')
    }
  }

  checkForOverflow (el) {
    if (!el) { return false }
    const fadebarHeight = 75 // 150
    const actionHeight = 0 // 147

    const scrollable = el.scrollHeight - el.clientHeight

    if ((el.scrollHeight - el.clientHeight) > (fadebarHeight + actionHeight) && (el.scrollTop < (scrollable - 75))) {
      return true
    } else {
      return false
    }
  }

  handleScroll (event) {
    const target = event.target
    if (target) {
      const scrollable = target.scrollHeight - target.clientHeight

      if ((target.scrollTop >= (scrollable - 75))) {
        this.basketTarget.classList.remove('is-overflowing')
      } else {
        this.basketTarget.classList.add('is-overflowing')
      }
    }
  }

}