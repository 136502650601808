import { Controller } from "@hotwired/stimulus"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { EventBus } from '../utils/event_bus'

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ["form", "filters", "body", "category", "ageRanges", "search","query", "content", "suggestionForm", "suggestions"]
 
  initialize() {
    this.setPlaceholder = this.setPlaceholder.bind(this);
  }

  connect() {
    window.addEventListener('resize', this.setPlaceholder) 
    this.setPlaceholder()
  }

  disconnect() {
    window.removeEventListener('resize', this.setPlaceholder) 
    clearAllBodyScrollLocks();
  }

  setPlaceholder() {
    this.searchTarget.placeholder = window.innerWidth > 550 ? 'Search our Catalog of Educational Videos' : 'Type here to search';
  }

  submit(event) {
    // Update the URL with the new query parameter
    const params = new URLSearchParams(location.search);
    params.set('q', this.queryTarget.value);
    if (this.hasContentTarget) {
      params.set('content', this.contentTarget.value);
    }
    
    params.set('category_id', this.categoryTarget.value);
    Array.from(this.ageRangesTarget.options).filter(o => o.selected).map(o => params.append('age_ranges[]', o.value));
    window.history.replaceState({}, '', `${location.pathname}?${params}`)    

    // this.formTarget.querySelector('button[type="submit"]').click()
    this.formTarget.requestSubmit()
  }

  debounceSubmit() {
    clearTimeout(this.timeout)
    this.queryTargets.forEach(e => e.value = this.searchTarget.value);
    this.timeout = setTimeout(() => {
        // this.formTarget.requestSubmit()
        this.submit()
        
      }, 250)
  }

  suggestions() {
    // this.suggestionsTarget.querySelector('button[type="submit"]').click()
    this.suggestionFormTarget.requestSubmit()
  }
  
  checkForEnter(event) {
    if (event.key == 'Enter') {
      this.suggestionsTarget.classList.remove("open")
      event.target.blur()
    }
  }

  setSearch(event) {
    this.queryTargets.forEach(e => e.value = event.params.query);
    this.searchTarget.value = event.params.query;
    this.suggestionsTarget.classList.remove("open")
    this.submit()
    this.logSearch()
  }

  handleBlur() {
    if (this.hasSuggestionsTarget) {
      setTimeout(() => this.suggestionsTarget.classList.remove("open"), 250)
    }
    this.logSearch()
  }

  filtersShow() {
    this.filtersTarget.classList.add("is-visible")
    document.body.classList.add("scroll-lock")
    // disableBodyScroll(this.bodyTarget, { reserveScrollBarGap: true });
  }

  filtersHide() {
    this.filtersTarget.classList.remove("is-visible")
    document.body.classList.remove("scroll-lock")
    // enableBodyScroll(this.bodyTarget);
  }  

  applyFilters() {
    this.filtersHide()
    this.submit()
    window.scrollTo(0, 0)
  }

  clearFilters() {
    this.categoryTarget.tomselect.clear()
    this.ageRangesTarget.tomselect.clear()
    // EventBus.emit('ts--clear')
    // this.submit()
  }

  logSearch() {
    const formData = new FormData(this.formTarget);
    const csrfToken = document.querySelector("meta[name='csrf-token']").content

    fetch("/search_logs", {
      method: 'POST', 
      headers: {
        'X-CSRF-Token': csrfToken 
      },
      body: formData
    })
    .catch(error => {
      console.error('Error logging search:', error);
    });
  }
}