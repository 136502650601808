import { Controller } from "@hotwired/stimulus"
import { EventBus } from '../utils/event_bus'

// Connects to data-controller="redirect"
export default class extends Controller {
  static values = {
    url: { type: String, default: ""}
  }

  initialize() {
    if (this.urlValue) {
      EventBus.emit('modal--hide');
      // setTimeout(() => Turbo.visit(this.urlValue), 250);
      Turbo.visit(this.urlValue);
    }
  }
}
