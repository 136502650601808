import { Controller } from '@hotwired/stimulus'
import { EventBus } from '../utils/event_bus'
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class extends Controller {

  connect() {
  }

  showModal() {
    clearAllBodyScrollLocks();
    EventBus.emit('modal--show');
  }

  hideModal(event) {
    event.preventDefault();
    clearAllBodyScrollLocks();
    EventBus.emit('modal--hide');
  }

}
