import { Controller } from '@hotwired/stimulus'
import { EventBus } from '../utils/event_bus'
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class extends Controller {
  static targets = [ "modal", "body"]
  static values = {
    closeModal: { type: Boolean, default: false },
    openModal: { type: Boolean, default: false }
  }

  initialize() {
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }  

  connect() {
    EventBus.on('modal--show', this.show);
    EventBus.on('modal--hide', this.hide);

    if (this.closeModalValue) {
      this.hide()
      // setTimeout(() => Turbo.visit(window.location.href), 250);
    }
    if (this.openModalValue) {
      this.show()
      // setTimeout(() => Turbo.visit(window.location.href), 250);
    }

    this.checkForOverflow = this.checkForOverflow.bind(this)

    this.createObserver();
    
    window.addEventListener('resize', this.checkForOverflow)
    this.checkForOverflow()
    setInterval(this.checkForOverflow, 500);
  }

  disconnect() {
    EventBus.off('modal--show', this.show);
    EventBus.off('modal--hide', this.hide);
  }

  close() {
    EventBus.emit('modal--hide');
  }

  show() {
    this.modalTarget.classList.add("is-visible")
    // disableBodyScroll(this.bodyTarget, { reserveScrollBarGap: true });
    const scrollBarGap = window.innerWidth - document.documentElement.clientWidth
    document.body.classList.add("scroll-lock");
    if (scrollBarGap > 0) {
      const computedBodyPaddingRight = parseInt(window.getComputedStyle(document.body).getPropertyValue('padding-right'), 10);
      let previousBodyPaddingRight = document.body.style.paddingRight;
      document.body.style.paddingRight = `${computedBodyPaddingRight + scrollBarGap}px`;
    }
  }

  hide() {
    // enableBodyScroll(this.bodyTarget);
    document.body.style.paddingRight = `0px`;
    this.modalTarget.classList.remove("is-visible")
    document.body.classList.remove("scroll-lock")
    setTimeout(() => this.bodyTarget.innerHTML = '', 250)
  }

  // Observer to watch for items being added or removed in the basket
  createObserver() {
    let observer;
  
    let options = {
      threshold: [0, 1.0]
    };
    
    this.observer = new MutationObserver(this.checkForOverflow);
    this.observer.observe(this.element, { childList: true, subtree: true });
  }

  checkForOverflow () {
    const el = this.bodyTarget;
    if (!el) { return false }
    const scrollable = el.scrollHeight - el.clientHeight;

    if ((el.scrollTop >= (scrollable - 75))) {
      this.modalTarget.classList.remove('is-overflowing')
    } else {
      this.modalTarget.classList.add('is-overflowing')
    }
  }

  handleScroll (event) {
    const target = event.target
    if (target) {
      const scrollable = target.scrollHeight - target.clientHeight

      if ((target.scrollTop >= (scrollable - 75))) {
        this.modalTarget.classList.remove('is-overflowing')
      } else {
        this.modalTarget.classList.add('is-overflowing')
      }
    }
  }  

}