import { Controller } from "@hotwired/stimulus"
import { EventBus } from '../utils/event_bus'

export default class extends Controller {
  static targets = ["modal", "notification"]

  initialize() {
    this.handleNotification = this.handleNotification.bind(this);
  }  

  connect() {
    EventBus.on('notification', this.handleNotification);
  }
  disconnect() {
    EventBus.off('notification', this.handleNotification);
  }

  handleNotification(params) {
    this.notificationTarget.innerHTML = params.notification;
    this.show()
    setTimeout(() => {
      this.close()
    }, 1500)
  }

  show() {
    this.modalTarget.classList.add("is-visible");
  }

  close() {
    this.modalTarget.classList.remove("is-visible");
  }
}
