import { Controller } from "@hotwired/stimulus"
import { EventBus } from '../utils/event_bus'

// Connects to data-controller="asset-modal"
export default class extends Controller {
  static targets = ["video"] 
  
  static values = {
    id: { type: String, default: "" },
  }

  initialize() {
    this.handleModalHide = this.handleModalHide.bind(this);
  }  

  connect() {
    EventBus.on('modal--hide', this.handleModalHide);

    const params = new URLSearchParams(location.search);
    params.set('asset_id', this.idValue);
    window.history.replaceState({}, '', `${location.pathname}?${params}`)
    
    this.videoTargets.forEach(v => {
      v.addEventListener('loadeddata', () => {
        try {
          v.play()
        } catch(err) {
          console.log("Couldn't auto play", err)
        }
      })
    })
  }

  handleModalHide() {
    const params = new URLSearchParams(location.search)
    params.delete('asset_id')
    window.history.replaceState({}, '', `${location.pathname}?${params}`)
  }
}
